// Bootstrap
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";
// Variables
@import "app/variables";
// Layout
@import "app/layout";
@import "app/layout-extra";
@import "app/layout-animation";
@import "app/top-navbar";
@import "app/sidebar";
@import "app/offsidebar";
@import "app/user-block";
@import "app/settings";
// Common
@import "app/typo";
@import "app/bootstrap-reset";
@import "app/bootstrap-custom";
@import "app/button-extra";
@import "app/placeholder";
@import "app/cards";
@import "app/circles";
@import "app/dropdown-extra";
@import "app/half-float";
@import "app/animate";
@import "app/slim-scroll";
@import "app/inputs";
@import "app/utils";
@import "app/print";
// Elements
@import "app/spinner";
// Charts
@import "app/radial-bar";
@import "app/chart-flot";
@import "app/chart-easypie";
// Form elements
@import "app/form-select2";
@import "app/form-tags-input";
@import "app/file-upload";
@import "app/summernote";
@import "app/typeahead";
// Tables
@import "app/table-extras";
@import "app/table-angulargrid";
// Maps
@import "app/gmap";
@import "app/vector-map";
// Extras
@import "app/timeline";
@import "app/todo";
@import "app/calendar";
@import "app/mailbox";
@import "app/plans";
#floating-label .form-group {
  display: flex;
  height: 55px;
}
#floating-label .control-label {
  font-size: 16px;
  font-weight: 400;
  opacity: 0.4;
  pointer-events: none;
  position: absolute;
  transform: translate3d(6px, 22px, 0) scale(1);
  transform-origin: left top;
  transition: 240ms;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset;
    box-shadow: 0 0 0 30px white inset;
}

#floating-label .form-group.focused .control-label {
  opacity: 1;
  transform: scale(0.75);
}
#floating-label .form-control {
  align-self: flex-end;
}
#floating-label .form-control::-webkit-input-placeholder {
  color: transparent;
  transition: 240ms;
}
#floating-label .form-control:focus::-webkit-input-placeholder {
  transition: none;
}
#floating-label .form-group.focused .form-control::-webkit-input-placeholder {
  color: #bbb;
}
.border-bottom {
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  border-bottom-style: solid;
}
.black-border-bottom {
  border-bottom-width: 1px;
  border-bottom-color: #b1b1b1;
  border-bottom-style: solid;
}
.row-padding-right {
  padding-right: 15px;
}
.border-padding-top {
  padding-bottom: 5px;
}
.form {
  width: 450px;
  margin: 0 auto;
  display: block;
}
@media only screen and (max-width: 600px) {
  .form {
    width: 320px;
    margin: 0 auto;
    display: block;
  }
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.display-block {
  display: block;
}
.rounded-img {
  border-radius: 50%;
  border: 1px solid #ffffff;
  background-color: #fff;
}
.page-title {
  padding-top: 30px;
  padding-bottom: 30px;
}
.profile-page-title {
  padding-bottom: 30px;
}
.header-button {
  min-width: 150px;
}
.has-float-label input:placeholder-shown:not(:focus) + *,
.has-float-label select:placeholder-shown:not(:focus) + *,
.has-float-label textarea:placeholder-shown:not(:focus) + * {
  font-size: 120% !important;
  opacity: 0.7;
  top: 1em !important;
}
.has-float-label input,
.has-float-label select,
.has-float-label textarea {
  padding-top: 1.7em !important;
}
.has-float-label label,
.has-float-label > span {
  font-size: 95% !important;
}
.has-float-label {
  margin: 20px 0px 10px 0px !important;
}
.disabled {
  background-color: white;
  border: 0 !important;
  color: #9e9e9e;
}
.ng-invalid.ng-touched:not(form) + label {
  color: red;
}
.custom-float-label-input.ng-invalid.ng-touched:not(form) {
  border-bottom: 2px solid red !important; /* red */
}
.custom-float-label-input {
  border-radius: unset;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding-top: 1.5em !important;
  border-bottom-width: 2px;
  width: 100%;
  background-color: white !important;
}
.custom-label {
  color: #9e9e9e;
  font-size: 95% !important;
  opacity: 1 !important;
}
.custom-input {
  border-radius: unset;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-width: 2px;
  width: 100%;
  background-color: white;
}
.white-bg {
  background: #fff;
}
.p-15 {
  padding: 15px;
}

.p-150 {
  padding: 150px;
}
.ng-select.ng-select-focused ::ng-deep .ng-select-container:after {
  border-color: #9e9e9e !important;
}
.ng-select ::ng-deep .ng-select-container:after {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.ng-select.ng-invalid.ng-touched ::ng-deep .ng-select-container:after {
  border-bottom: 2px solid red !important;
}
.ng-select.ng-select-focused
  ::ng-deep
  .ng-select-container
  .ng-value-container
  .ng-placeholder,
.ng-select.ng-select-focused
  ::ng-deep
  .ng-select-container
  .ng-arrow-wrapper
  .ng-arrow {
  color: #9e9e9e !important;
}
.save-button {
  text-align: right;
  line-height: 70px;
}
.card-text {
  font-family: "Source Sans Pro", sans-serif;
  color: #4d4f5b;
  font-size: 22px;
  font-weight: bold;
  padding-top: 30px;
}
.card-bottom {
  padding-bottom:10px
}
.control {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding-left: 0px;
  border-bottom-color: #e9e9f0;
  border-bottom-width: 2px;
  font-size: 18px;
}
.green-button {
  padding-left: 60px;
  padding-right: 60px;
  background-color: #69e4a6;
  border-color: #69e4a6;
  font-size: 15px;
}
.green-round {
  display: inline;
  margin: 4px 10px;
  padding: 3px 25px;
  border-radius: 9px;
  font-size: 11px;
  font-weight: 700;
  background: #69e4a6;
  color: #46435d;
}
.g-button-position {
  text-align: center;
  padding-top: 60px;
  padding-bottom:15px
}
.content-center {
  justify-content: center;
}
.title-card {
  padding: 15px;
  font-weight: 400;
  font-size: 17px;
}
.ng-select .ng-select-container:after {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.ng-select.ng-invalid.ng-touched .ng-select-container:after {
  border-bottom: 2px solid red !important;
}
.ng-select.ng-select-focused ::ng-deep .ng-select-container .ng-value-container .ng-placeholder,
.ng-select.ng-select-focused ::ng-deep .ng-select-container .ng-arrow-wrapper .ng-arrow {
  color: #9e9e9e;
}
.ng-select.ng-select-focused ::ng-deep  .ng-select-container:after {
  border-color: #9e9e9e !important;
  border-width: 2px;
}
.ng-select.ng-invalid.ng-touched
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  color: red;
}
.margin-10 {
  margin: 10px;
}
.content-wrapper {
  padding: 0px !important;
  border-top:0px !important;
}
.footer-container {
  margin-left: 0px !important;
}
datatable-body-row {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.button-style {
  padding: 5px 15px;
  background-color: #ff7285;
  border: none;
  font-size: 15px;
  border-radius: 4px;
  cursor: pointer;
  min-width: 150px;
  color: white;
}
.ng-select.custom {
  padding-bottom: 0px;
}
.btn-purple-custom {
  display: block;
  width: 43%;
  margin: 0 auto;
  background-color: #43425d;
  border-color: #43425d;
  padding-top: 12px;
  padding-bottom: 12px;
  opacity: 1;
  color: #ffffff;
  font-size: 15px;
}
.mt-70 {
  margin-top: 70px;
}
input[type="text"]:disabled {
  border-style: dotted solid;
  color: #9e9e9e !important;
}
input[type="number"]:disabled {
  border-style: dotted solid;
  color: #9e9e9e !important;
}
input[type="email"]:disabled {
  border-style: dotted solid;
  color: #9e9e9e !important;
}
.btn-primary:hover {
  background: #1062e5;
}
.btn-primary {
  background: #3b86ff;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #5d9cec;
  border-color: #5d9cec;
}
.ng-select .ng-select-container{
  min-height: 47px
}
.mb-30{
  margin-bottom: 30px;
}
.padding-inner-table{
  padding:10px 30px !important;
}
ng-dropdown-panel{
  margin-top: 20px !important
  }
.pointer{
  cursor: pointer;
}
.status-div{
  padding: 2px 0px;
  text-align: center;
  margin: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  border-radius: 20px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.status-div-table{
  font-weight: 600;
  display: inline-block
}

.PIANIFICATA,.planned,.PLANNED,.PLANNED_REFUND,.INITIATING{
  background-color: #fec988 !important;
  color: #000!important;
}
.PAGATA,.paid,.PAID,.REFUNDED,.CONFIRMED{
  background-color: #33cc33 !important;
  color: #000!important;
}
.PENDING,.PENDING_REFUND,.WAITING_TOPUP_REFUND{
  background-color: #e0cd68 !important;
  color: #000!important;
}
.INSOLUTA,.unsolved,.UNSOLVED{
  background-color: #a3a6b3 !important;
  color: #000!important;
}
.CHIUSA,.terminata,.closed,.CLOSED{
  background-color: #a3a6b3 !important;
  color: #000!important;
}
.PROCESSING,.PROCESSING_REFUND{
  background-color: #f1a922 !important;
  color: #000!important;
}
.refunded_partially,.REFUNDED_PARTIALLY{
  background-color: #2faae2 !important;
  color: #FFF!important;
}
.refunded_fully,.REFUNDED_FULLY{
  background-color: #1357eb !important;
  color: #FFF!important;
}
.DISPUTE,.DISPUTING{
  background-color: #d47343 !important;
  color: #FFF!important;
}
.DISPUTED{
  background-color: #c50505 !important;
  color: #FFF!important;
}
.LOST_DISPUTE,.WON_DISPUTE{
  background-color: #30b0bf !important;
  color: #FFF!important;
}
.WITHDRAWN_DISPUTE{
  background-color: #bf30a7 !important;
  color: #FFF!important;
}
.PAID_LOST_DISPUTE,.PAID_WON_DISPUTE{
  background-color: #0f6dd2 !important;
  color: #FFF!important;
}
.PAID_WITHDRAWN_DISPUTE{
  background-color: #8b30bf !important;
  color: #FFF!important;
}
.REFUND_DISPUTE{
  background-color: #11b252 !important;
  color: #FFF!important;
}
.REFUNDED_DISPUTE{
  background-color: #0e8f35 !important;
  color: #FFF!important;
}
.ONGOING_INQUIRY{
  background-color: #f39566 !important;
  color: #FFF!important;
}
.CLOSED_INQUIRY{
  background-color: #5bf15b !important;
  color: #000!important;
}
.attiva,.attivo,.Attivo,.nuova,.Nuovo,.ACTIVE{
  background-color: #45f89e !important;
  color: #000!important;
}
.active_status{
  background-color: #45f89e !important;
  color: #000!important;
}
.aperta,.new,.NEW{
  background-color: #cbe42f !important;
  color: #000!important;
}
.error,.ERROR,.ERROR_REFUND{
  background-color: #cc3300 !important;
  color: #FFF!important;
}
.scaduta,.expired,.EXPIRED {
  background-color: #717f16 !important;
  color: #CCC!important;
}
.annullata,.canceled,.CANCELED {
  background-color: #fd7387 !important;
  color: #FFF!important;
}
.Bloccato,.bloccato,.blocked,.BLOCKED{
  background-color: #e00c2c !important;
  color: #FFF!important;
}
.frozen,.FROZEN{
  background-color: #9dcdec !important;
  color: #000!important;
}
.open-status-i{
  padding: 5px;
  border-radius: 50px;
}
.open-status-i:hover{
  background-color: #a3a6b328 !important
}
:ng-deep datatable-scroller{
  width: 100% !important;
}
datatable-scroller{
  width: 100% !important;
}
.pr-0{
  padding-right: 0px;
}
.pl-0{
  padding-left: 0px;
}
.icon-col-status{
  line-height: 3.3;
 }
@media  (max-width: 1168px) {
  .container {
    padding: 10px !important;
     max-width: unset !important;
  }
}
.user-menu {
  left: 0px !important;
}
.top {
  margin-top:50px
}
.top-d {
  margin-top:30px
}
.l-p {
  margin-left:10px;
  margin-top:5px
}
input[type="checkbox"] + .label-check {
  margin-left: 10px;
}
.datatable-row-detail {
  height: 100% !important;
}
::ng-deep .datatable-row-detail {
  height: 100% !important;
}
::ng-deep .datatable-body-cell {
  padding: 0px !important;
  display: flex !important;
  justify-content: space-evenly;
  align-items: center;
}
.datatable-body-cell {
  padding: 0px !important;
  display: flex !important;
  justify-content: space-evenly;
  align-items: center;
}
.datatable-body-row>.datatable-row-center{
  padding: 0px 15px !important;
}
.prl-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.empty-row.ng-star-inserted {
  display:none !important;
}
.status-pencil{
  line-height: 43px;
}
.wrapper{
  background: #fbfbfb;
}
.sec-butt {
  margin-left:5px
}
.f-right {
  display: flex;
  justify-content: flex-end;
}
.start {
  width:58px;
  justify-content: flex-start !important;
}
.details-button {
  padding: 5px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  min-width: 150px
}
.table-margin{
  margin: 10px;
}
.center-row{
  justify-content: center;
}
.datatable-body-cell-label{
  width: 100%;
}
::ng-deep .datatable-body-cell-label{
  width: 100%;
}
.a-center {
  text-align: center !important
}
.save60 {
  padding-left: 60px;
  padding-right: 60px;
}
.datatable-icon-right:before {
  content: "\70" !important; 
}
.card{
  margin: 5px;
  border-color: transparent;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.17);  
}
.ngx-datatable.material .datatable-footer {
  border-top: 1px solid #666666 !important;
}
.select-pad{
  padding-bottom: 0em !important;
}
.swal2-popup .swal2-title{
  display: block;
  position: relative;
  max-width: 100%;
  font-size: 1.375em !important; 
  font-weight: 400 !important;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}
.m-bottom-0{
  margin-bottom:0px;
  margin-top: 3px;
}

